<template>
  <section>
    <div class="card text-left">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-8">
            <h3 class="mb-0">Participant of {{ event.name }}</h3>
            <strong>{{ formatDate(event.datetime) }}</strong> <br>
            Distance: {{ event.distance }} KM / Elevation: {{ event.elevation }} Meters <br>
            Min Cap: {{ event.min_cap }} W/KG / Max Cap: {{ event.max_cap }} W/KG <br>
          </div>

          <div class="col-4">
            <router-link class="btn btn-sm btn-outline-light"
              :to="{ name: 'AdminEventsParticipants', params: { id: eventId } }">back to list of participants
            </router-link>

            <div v-if="participant" class="d-block mt-2">
              <strong>Application date: {{ formatDate(participant.created_at) }}</strong><br>

              <strong v-if="participant.status === 'removed'" class="text-danger">
                Removed date: {{ formatDate(participant.removed_at) }}
              </strong>

              <strong v-if="participant.status === 'approved'" class="text-success">
                Approved date: {{ formatDate(participant.approved_at) }}
              </strong>

              <strong v-if="participant.status === 'declined'" class="text-danger">
                Declined date: {{ formatDate(participant.declined_at) }}
                <span class="d-block text-left">{{ participant.decline_reason }}</span>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="participant">
      <div class="col-9">
        <div class="card">
          <div class="card-body p-0">

            <div class="row">
              <div class="col-2 bg-light">User ID</div>
              <div class="col-4">
                {{ participant.userId }} <br>
                {{ participant.userUuId ? participant.userUuId : participant.userUuid }}<br>
              </div>

              <div class="col-2 bg-light">Event ID</div>
              <div class="col-4">
                {{ participant._id }} <br>
                {{ participant.serverEventId }}
              </div>

              <div class="col-2 bg-light">Name</div>
              <div class="col-4">{{ participant.firstName }} {{ participant.lastName }}</div>

              <div class="col-2 bg-light">Email</div>
              <div class="col-4">{{ participant.email }}</div>

              <div class="col-2 bg-light">Gender</div>
              <div class="col-4">{{ parseInt(participant.gender) === 0 ? 'Male' : 'Female' }}</div>

              <template v-if="participant.code">
                <div class="col-2 bg-light">Phone</div>
                <div class="col-4">{{ participant.code }}-{{ participant.phone }}</div>
              </template>

              <div class="col-2 bg-light">DOB</div>
              <div class="col-4">
                {{ formatDate(participant.dob, 'd') }}
                <div class="input-group mb-3">
                  <input type="date" v-model="newDob" class="form-control">
                  <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="updateDob()">Update
                    Dob
                  </button>
                </div>
              </div>
              <div class="col-2 bg-light">DOB File</div>
              <div class="col-4">
                <button v-if="participant.dobCertificateLink" class="btn btn-outline-light btn-sm"
                  @click="downloadAttachment(participant.dobCertificateLink)">download
                </button>
                <span v-else>N/A</span>
              </div>


              <template v-if="participant.platform">
                <div class="col-2 bg-light">Platform</div>
                <div class="col-4">{{ participant.platform }}</div>
              </template>

              <div class="col-2 bg-light">Height</div>
              <div class="col-4">
                {{ participant.height }} CM
                <div class="input-group mb-3">
                  <input type="text" v-model="newVerifiedHeight" class="form-control" placeholder="Enter new height">
                  <button class="btn btn-outline-secondary" type="button" id="button-addon2"
                    @click="updateHeight()">Update Height
                  </button>
                </div>
              </div>


              <div class="col-2 bg-light">Weight</div>
              <div class="col-4">
                {{ participant.weight }} Kg
                <div class="input-group mb-3">
                  <input type="text" v-model="newVerifiedWeight" class="form-control" placeholder="Enter new weight">
                  <button class="btn btn-outline-secondary" type="button" id="button-addon2"
                    @click="updateWeight()">Update Weight
                  </button>
                </div>
              </div>

              <template v-if="participant.fitFileType">
                <div class="col-2 bg-light">Fit File Type</div>
                <div class="col-4">{{ participant.fitFileType }}</div>
              </template>

              <div class="col-2 bg-light">FTP</div>
              <div class="col-4">{{ participant.ftp }}</div>

              <div class="col-2 bg-light">Avg. Watt</div>
              <div class="col-4">{{ participant.avg_watt }}</div>

              <template v-if="participant.team">
                <div class="col-2 bg-light">Team / Team Name</div>
                <div class="col-4">{{ participant.team === 'i' ? 'No' : 'Yes /' }} {{ participant.teamName }}</div>
              </template>

              <template >
                <div class="col-2 bg-light">Allow To Send Marketing Emails</div>
                <div class="col-4" v-if="typeof participant.user.userData.data.PlayerData.PlayerPersonalStruct.IsAllowedToSendMarketingEmails === 'undefined'">
                    false
                </div >
                <div v-else class="col-4"  >
                  {{ (participant.user.userData.data.PlayerData.PlayerPersonalStruct.IsAllowedToSendMarketingEmails)
                  ? participant.user.userData.data.PlayerData.PlayerPersonalStruct.IsAllowedToSendMarketingEmails : 'false' }}
                </div >
              </template>
 
              <template v-if="participant.stravaProfileLink">
                <div class="col-2 bg-light">Strava Profile</div>
                <div class="col-4">
                  <a target="_blank" :href="participant.stravaProfileLink">{{ participant.stravaProfileLink }}</a>
                </div>
              </template>

              <template v-if="participant.smartTrainerDeviceModelName">
                <div class="col-2 bg-light">Trainer Model Name</div>
                <div class="col-4">{{ participant.smartTrainerDeviceModelName }}</div>
              </template>

              <template v-if="participant.smartTrainerDeviceSerialNumber">
                <div class="col-2 bg-light">Trainer Serial Number</div>
                <div class="col-4">{{ participant.smartTrainerDeviceSerialNumber }}</div>
              </template>

              <template v-if="participant.idType">
                <div class="col-2 bg-light">ID Type</div>
                <div class="col-4">{{ participant.idType }}</div>

                <div class="col-2 bg-light">ID Expiry Date</div>
                <div class="col-4">{{ formatDate(participant.expiryDate, 'date') }}</div>

                <div class="col-2 bg-light">National ID Front</div>
                <div class="col-4">
                  <button v-if="participant.nationalIdFront" class="btn btn-outline-light btn-sm"
                    @click="downloadAttachment(participant.nationalIdFront)">download
                  </button>
                  <span v-else class="text-danger">None</span>
                </div>

                <div class="col-2 bg-light">National ID Back</div>
                <div class="col-4">
                  <button v-if="participant.nationalIdBack" class="btn btn-outline-light btn-sm"
                    @click="downloadAttachment(participant.nationalIdBack)">download
                  </button>
                  <span v-else class="text-danger">None</span>
                </div>
              </template>

              <div class="col-2 bg-light">Weight File</div>
              <div class="col-4">
                <template v-if="participant.weightType === 'link'">
                  <a :href="participant.weightLink" target="_blank">{{ participant.weightLink }}</a>
                </template>

                <template v-else>
                  <button class="btn btn-outline-light btn-sm" v-if="participant.weightFile"
                    @click="downloadAttachment(participant.weightFile)">
                    download
                  </button>
                  <span v-else class="text-danger">None</span>
                </template>
              </div>

              <template v-if="participant.garminFile">
                <div class="col-2 bg-light">Garmin File</div>
                <div class="col-4">
                  <button class="btn btn-outline-light btn-sm" v-if="participant.garminFile"
                    @click="downloadAttachment(participant.garminFile)">
                    download
                  </button>
                  <span v-else class="text-danger">None</span>
                </div>
              </template>

              <template v-if="participant.smartTrainerDeviceVideo">
                <div class="col-2 bg-light">Trainer Device Video</div>
                <div class="col-4">
                  <button v-if="participant.smartTrainerDeviceVideo" class="btn btn-outline-light btn-sm"
                    @click="downloadAttachment(participant.smartTrainerDeviceVideo)">download
                  </button>
                  <span v-else class="text-danger">None</span>
                </div>
              </template>

            </div>
          </div>
        </div>
      </div>
      <div class="col-3">

        <template>
          <h4 class="mb-0">Change Team / Jersey</h4>
          <div :class="borderClass(participant.status)" class="card border">
            <div class="card-body p-2 rounded border">
              <div class="flex">
                <label for="teamId" class="">Is Leader?</label>
                <label class="custom-toggle">
                  <input v-model="participant.isLeader" :required="true" type="checkbox" />
                  <span class="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                </label>
              </div>

              <div>
                <label for="teamId" class="form-control-label">Choose a Team</label>
                <select id="teamId" class="form-control" v-model="updateTeamForm.teamId">
                  <option v-for="(team, t) of eventTeams" :key="t" :value="team.TeamId">
                    {{ team.Name }}
                  </option>
                </select>
              </div>

              <div class="mt-2">
                <label for="jerseyId" class="form-control-label pb-0">Choose a Jersey</label>


                <select id="jerseyId" class="form-control" v-model="updateTeamForm.jerseyId">
                  <option v-for="(jersey, j) of eventJerseys" :key="j" :value="jersey.Id"  >
                    {{ jersey.Name }}
                  </option>
                </select>
              </div>

              <div class="mt-2">
                <label for="categoryId" class="form-control-label pb-0">Choose a Category</label>
                <select id="categoryId" class="form-control" v-model="updateTeamForm.categoryId">
                  <option v-for="(category, k) of categories" :key="k" :value="category.CategoryId">
                    {{ category.Label }}
                  </option>
                </select>
              </div>

              <button @click="updateParticipantTeamHandler(participant._id)"
                class="btn btn-block btn-light text-uppercase rounded-0 mt-3" type="button">
                Update Team and Jersey
              </button>

            </div>
          </div>
        </template>

        <h4 class="mb-0">Team & Jersey Settings</h4>
        <div :class="borderClass(participant.status)" class="card border">
          <div class="card-body p-2 rounded border">
            <div class="p-2">
              <p class="m-0">Assigned Team: <strong>{{ participant.teamName || 'Individual' }}</strong></p>
              <p class="m-0">Assigned Jersey: <strong>{{ participant.jerseyName || 'None' }}</strong></p>
              <p class="m-0">Assigned Category: <strong>{{ participant.categoryName || 'None' }}</strong></p>
            </div>
          </div>
        </div>

        <div :class="borderClass(participant.status)" class="card border" v-if="participant.status === 'pending'">
          <div class="card-body p-2">
            <div class="text-center mt-3 mb-2">
              <div class="btn-group" role="group">
                <button class="btn btn-sm btn-outline-success" @click="updateStatus('approved', participant._id)"
                  v-if="participant.status === 'pending'">
                  Approve
                </button>
                <button class="btn btn-sm btn-outline-warning" data-target="#declinedModal" data-toggle="modal"
                  type="button" @click="declineIt(participant._id)" v-if="participant.status === 'pending'">
                  Reject
                </button>
                <button class="btn btn-sm btn-outline-danger" @click="updateStatus('removed', participant._id)"
                  v-if="participant.status !== 'removed'">Remove
                </button>
              </div>
            </div>
          </div>
        </div>

        <div :class="borderClass(participant.status)" class="card border" v-if="participant.status === 'approved'">
          <div class="card-body p-2">
            <div class="text-center mt-3 mb-2">
              <div class="btn-group" role="group">
                <button class="btn btn-sm btn-outline-danger" @click="updateStatus('removed', participant._id)">Remove</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Decline  Modal -->
      <div id="declinedModal" aria-hidden="true" aria-labelledby="declinedModalLabel" class="modal fade" role="dialog"
        tabindex="-1">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="declinedModalLabel" class="modal-title">Reason of rejection</h5>
              <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <textarea v-model="declineReason" class="form-control text-danger border-danger" placeholder="Write Here"
                required rows="5"></textarea>
            </div>
            <div class="modal-footer">
              <button class="btn btn-outline-light" data-dismiss="modal" type="button">Cancel</button>
              <button class="btn btn-danger" @click="updateStatus('declined', declineId)">
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";

export default {
  name: 'AdminEventsParticipant',
  inject: ["dropdowns"],
  data() {
    return {
      eventId: this.$route.params.id,
      participantId: this.$route.params.participantId,
      declineReason: null,
      declineId: null,
      newVerifiedWeight: null,
      newVerifiedHeight: null,
      newDob: null,
      updateTeamForm: {
        categoryId: 0,
        jerseyId: undefined,
      },
    };
  },

  computed: {
    ...mapGetters('event', ['event']),

    eventTeams() {
      if (!this.event.teams) return;
      const teams = Object.assign([], this.event.teams);
      teams.unshift({ _id: "", Name: "None" });
      return teams;
    },

    eventJerseys() {
      if (!this.dropdowns.jerseies) return;
      const jerseys = Object.assign([], this.dropdowns.jerseies);
      jerseys.unshift({ Id: 0, Name: "None" });
      return jerseys;
    },

    participant() {
      if (!this.event.participants) return;
      const participant= this.event.participants.filter(participant => participant._id === this.participantId)[0];
      participant.isLeader = participant.isLeader ? true : false;
      return participant;
    },

    categories() {
      if (!this.dropdowns.categories) return;
      const categories = Object.assign([], this.dropdowns.categories);
      categories.unshift({ CategoryId: 0, Label: "All" });
      return categories;
    },

  },

  mounted() {
    this.$store.dispatch('event/event', this.eventId);
    this.updateTeamForm.jerseyId = this.participant?.jerseyId === "" ? undefined : this.participant?.jerseyId;
    this.updateTeamForm.categoryId = this.participant?.categoryId === -1 ? 0 : this.participant?.categoryId;
  },

  methods: {
    declineIt(id) {
      this.declineId = id;
    },

    updateWeight() {
      const numbers = /^[0-9]+$/;
      if (!this.newVerifiedWeight || !this.newVerifiedWeight.match(numbers) || this.newVerifiedWeight <= 0) {
        alert('Verified weight must be number only');
        return false;
      }

      const payload = {
        participant_id: this.participantId,
        weight: this.newVerifiedWeight ?? this.participant.weight,
      }

      this.$store.dispatch('event/updateParticipantWeightHeight', payload);
      this.participant.weight = this.newVerifiedWeight || this.participant.weight
      this.newVerifiedWeight = null
    },

    updateHeight() {
      const numbers = /^[0-9]+$/;
      if (!this.newVerifiedHeight || !this.newVerifiedHeight.match(numbers) || this.newVerifiedHeight <= 0) {
        alert('Verified height must be number only');
        return false;
      }

      const payload = {
        participant_id: this.participantId,
        height: this.newVerifiedHeight ?? this.participant.height,
      }

      this.$store.dispatch('event/updateParticipantWeightHeight', payload);
      this.participant.height = this.newVerifiedHeight || this.participant.height
      this.newVerifiedHeight = null
    },

    updateDob() {
      if (!this.newDob) {
        alert('DOB cannot be blank');
        return false;
      }

      const payload = {
        participant_id: this.participantId,
        dob: this.newDob,
      }

      this.$store.dispatch('event/updateParticipantWeightHeight', payload);
      this.participant.dob = this.newDob
      this.newDob = null
    },

    borderClass(value) {
      return {
        'border-success': value === 'approved',
        'border-danger': value === 'declined',
        'border-danger2': value === 'removed',
        'border-info': value === 'pending'
      }
    },

    formatDate(value, date = null) {
      if (!value) return;

      value = value.replace('T', ' ').replace('Z', '');
      if (!date)
        return value ? dayjs(value).format('DD/MM/YYYY hh:mm A') : null;
      else
        return value ? dayjs(value).format('DD/MM/YYYY') : null;
    },

    async updateParticipantTeamHandler(participantId) {
      if (!this.updateTeamForm.jerseyId) {
        alert("Please select a Jersery");
        return;
      }
      if (this.participant.status !== 'approved') {
        alert("Please Approve the user first!");
        return;
      }

      const payload = {
        participantId,
        userId: this.participant.userId,
        gameEventId: this.event.serverEventId,
        status: this.participant.status,
        isLeader: (this.participant.isLeader) ? this.participant.isLeader : false,
        oldTeamId: this.participant.teamId,
        oldJerseyId: this.participant.jerseyId,
        teamId: this.updateTeamForm.teamId || "",
        jerseyId: this.updateTeamForm.jerseyId || "",
        categoryId: this.updateTeamForm.categoryId || "",
        teamName: this.eventTeams.filter(team => team.TeamId === this.updateTeamForm.teamId)[0].Name,
        jerseyName: this.eventJerseys.filter(jersey => jersey.Id === this.updateTeamForm.jerseyId)[0].Name,
        categoryName: this.categories.filter(cat => cat.CategoryId === this.updateTeamForm.categoryId)[0].Label,
      }

      const resp = await this.$store.dispatch('event/updateParticipantTeam', payload);
      if (resp && resp.status) {
        await this.$router.push({ name: 'AdminEventsParticipants', params: { id: this.eventId } })
      }
    },

    updateStatus(status, id) {
      const payload = {
        status: status,
        participant_id: id,
        event_id: this.eventId,
      }

      Swal.fire({
        title: 'Are you sure?',
        text: `You would like to ${status.toUpperCase()} this participant`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, ${status.toUpperCase()} it!`,
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {

          if (payload.status === 'declined' && this.declineReason) {
            payload.decline_reason = this.declineReason;
          }

          this.$store.dispatch('event/update_status', payload).then(res => {
            if (res && res.status) {
              this.$router.push({ name: 'AdminEventsParticipants', params: { id: this.eventId } })
            }
          });

        }
      });
    },

    async downloadAttachment(url) {
      const res = await this.$store.dispatch('event/downloadAttachment', { url });
      if (res.status && res.data.url) {
        window.open(res.data.url);
        return;
      }
      alert('There seems to be a problem with file downloading from server. Please try again!');
    },

  }

}
</script>

<style scoped>
.row {
  font-size: 12px;
}

.row .col-4 {
  padding: 6px 10px;
}

.row .bg-light {
  padding: 10px;
  text-transform: uppercase;
  border-bottom: 1px solid #e7e7e7;
  background-color: #f7f7f7 !important;
}

.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.flex {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.flex label {
  margin: 0;
}

.flex .custom-toggle {
  margin-left: 15px;
}
</style>
